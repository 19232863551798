<template>
  <div>
    <!-- todo - https://stackoverflow.com/questions/50392918/vuetify-how-to-preselect-active-tab
     -->
    <v-tabs v-model="activetab" align-with-title>
      <v-tab  v-for="tab of tabs"  :key="tab.id" @click="WarnUser(tab.to)"
        :id="`warrantTransSequenceTab${tab.id}`"
        :class="`warrant-trans-sequence-tab${tab.id}`">
        <span>{{ tab.caption }}</span>
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>
import { getProgramDetailsByParams } from '@/components/War/Transaction/warTransaction.util';

export default {
  name: 'WarrantsAndAppropriations',
  components: {
  },
  data: () => ({
    dialog: false,
    path: '',
    activetab: 0,
  }),
  methods: {
    WarnUser(path) {
      this.path = path;
      const currentpath = this.$router.currentRoute.path;
      if (path.path !== currentpath) {
        this.$router.push(path);
      }
    },
  },
  computed: {
    tabs() {
      const [programDetails, program] = getProgramDetailsByParams(this);
      const updatedTabList = [
        { id: 1, to: { path: `/${program}/transactions` }, caption: programDetails.programName },
        { id: 2, to: { path: `/${program}/browse/departments` }, caption: 'Browse' },
        { id: 3, to: { path: `/${program}/reports` }, caption: 'Reports' },
        { id: 4, to: { path: `/${program}/utils/fundreconciliation/reconTransaction` }, caption: 'Utils' },
      ];
      return updatedTabList;
    },
  },
};
</script>
